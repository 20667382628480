import React, { useState, useContext, useRef } from 'react'
import { Auth } from 'aws-amplify'
import { navigate } from 'gatsby'
import { GlobalStateContext } from '../context/GlobalContextProvider'
import * as styleLogin from './login.module.css'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'

const LoginIndex = () => {
  const { gAuth, gStripe } = useContext(GlobalStateContext)
  const [user, setUser] = useState({})
  const [btnDisabled, setBtnDisabled] = useState(true)
  const loginBtn = useRef(null)
  const [pagePwd, setPagePwd] = useState('')
  const [loginMail, setMail] = useState('')
  const [isVerifyCode, setVerifying] = useState(false) //判斷顯示輸入mail或顯示輸入密嗎
  const [isSigning, setIsSigning] = useState(false) //circle progress
  const [errHint, setErrHint] = useState(false)
  const [mailChk, setMailChk] = useState(true)

  // 輸入完帳號時，按下enter等於點擊登入鍵
  const handleKeydown = (e) => {
    if (e.target.value !== '' && e.keyCode === 13) {
      loginBtn.current.click()
    }
  }
  return (
    <Box
      sx={{
        flexDirection: 'column',
        display: 'flex',
        height: '100vh',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          padding: '24px 10px 0 10px',
          maxWidth: '360px',
          margin: 'auto',
        }}
      >
        {/* <Box style={{ display: 'flex', justifyContent: 'center' }}>
				<img
					alt='logo'
					width={100}
					src='https://play-lh.googleusercontent.com/1aJhnph1EOYw8Uxv1OVctDs0e0vAAUbXlkBcyhAT3WGHLdcDraiRbxji4sdKn4PVUA=s360'
				/>
			</Box> */}
        <Typography
          sx={{
            textAlign: 'center',
            letterSpacing: '2.5px',
            fontSize: '36px',
            fontWeight: 'bold',
          }}
        >
          SESAME Biz
        </Typography>
        <input
          placeholder="メールアドレス"
          value={loginMail}
          onKeyDown={(e) => {
            handleKeydown(e)
          }}
          onChange={(e) => {
            setMail(e.target.value)
            const mailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/
            const mailChk = mailReg.test(e.target.value)
            setBtnDisabled(!mailChk) // 驗證輸入，若不符合email格式則無法按下登入按鈕
            setMailChk(mailChk)
          }}
          style={{ display: isVerifyCode ? 'none' : 'block' }}
          className={styleLogin.loginBox}
        />
        <Typography
          variant="h6"
          color="error"
          sx={{
            display: mailChk ? 'none' : 'block',
            textAlign: 'center',
            marginBottom: '30px',
          }}
        >
          メールアドレスの形式が正しくありません
        </Typography>

        <Box sx={{ display: isVerifyCode ? 'none' : 'block' }}>
          <Typography variant="h5" sx={{ textAlign: 'center' }}>
            <a
              href="https://jp.candyhouse.co/pages/sesamebiz_terms"
              target="_blank"
              style={{
                color: '#28aeb1',
                fontWeight: 'bold',
                display: 'inline',
                cursor: 'pointer',
              }}
            >
              利用規約
            </a>
            と
            <a
              href="https://jp.candyhouse.co/pages/privacy_policy"
              target="_blank"
              style={{
                color: '#28aeb1',
                fontWeight: 'bold',
                display: 'inline',
              }}
            >
              プライバシーポリシー
            </a>
            に同意いただける場合はログインして下さい
          </Typography>
        </Box>

        <Button
          ref={loginBtn}
          fullWidth
          sx={{
            color: '#fff',
            height: '35px',
            marginTop: '16px',
            display: isVerifyCode ? 'none' : 'block',
            fontSize: '12px',
          }}
          variant="contained"
          color="primary"
          disabled={btnDisabled}
          onClick={async () => {
            // [aws用戶登入-1(客製化驗證)]自動以輸入的email在aws用戶池註冊
            // 經過sign up challenge的lambda
            await Auth.signUp({
              username: loginMail,
              password: 'Aa123456',
            })
              .then((user) => {})
              .catch((err) => {
                console.log(err)
              })

            // [aws用戶登入-2(客製化驗證)]拿註冊好的aws用戶登入
            // 並經過sign up challenge =>define auth challenge => verify auth challange的lambda
            await Auth.signIn(loginMail)
              .then((user) => {
                // console.log('驗證', user)
                if (
                  user.challengeName === 'CUSTOM_CHALLENGE' &&
                  typeof window !== 'undefined'
                ) {
                  // console.log('login', loginMail)
                  gAuth.setLoginMail(loginMail)
                  setVerifying(true)
                  setErrHint(false)
                  setPagePwd('')
                  setUser(user)
                }
              })
              .catch((err) => {
                console.log(err)
              })
          }}
        >
          ログイン
        </Button>
        <Box style={{ display: isVerifyCode ? 'block' : 'none' }}>
          <CircularProgress
            color="primary"
            sx={{
              display: isSigning ? 'block' : 'none',
              margin: '0 auto',
            }}
          />
          <Typography
            variant="h5"
            color="initial"
            sx={{
              display: isVerifyCode ? 'block' : 'none',
              textAlign: 'center',
            }}
          >
            メールに送信された確認コードを入力して下さい
          </Typography>

          <input
            variant="standard"
            placeholder="1234"
            type="number"
            value={pagePwd}
            disabled={!isVerifyCode}
            className={styleLogin.loginBox}
            onChange={(e) => {
              const currentPWD = e.target.value
              if (e.target.value.length <= 4) {
                setPagePwd(currentPWD)
              }
              if (currentPWD.length === 4) {
                setIsSigning(true)
                Auth.sendCustomChallengeAnswer(user, currentPWD) // 先經過create challenge的lambda
                  .then((user) => {
                    // console.log('輸入完驗證碼', user)
                    setIsSigning(false)
                    if (user.signInUserSession) {
                      gStripe.getStripeInfo(gAuth.loginMail)
                      // console.log('驗證通過', user)
                      navigate('/')
                    } else {
                      setMail('')
                      setPagePwd('')
                      setErrHint(true)
                    }
                  })
                  .catch((err) => {
            //        console.log('登入驗證失敗', err)
                  })
              }
            }}
          />

          <Typography
            variant="h6"
            color="error"
            sx={{
              display: errHint ? 'block' : 'none',
              textAlign: 'center',
              marginBottom: '30px',
            }}
          >
            入力された認証コードが正しくありません。認証コードが再発行されていますので、新しく受け取ったコードを入力して下さい。
          </Typography>

          <Typography
            variant="h5"
            sx={{
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <a
              size="small"
              onClick={() => {
                setVerifying(false)
                setErrHint(false)
                setMail('')
              }}
              sx={{ margin: 'auto' }}
              style={{
                color: '#28aeb1',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <KeyboardArrowLeftIcon />
              前ページに戻る
            </a>
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <img
          style={{ width: '36%' }}
          src="https://cdn.shopify.com/s/files/1/0016/1870/6495/files/busniess.png?v=1663835284"
        />
        <img
          style={{ width: '36%' }}
          src="https://cdn.shopify.com/s/files/1/0016/1870/6495/files/girlAndDoor-07.png?v=1663835270"
        />
      </Box>
    </Box>
  )
}

export default LoginIndex
